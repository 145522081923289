
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

iframe#webpack-dev-server-client-overlay{display:none!important}

.center .slick-center .project {
  padding: 10px;
  opacity: 1;
}
.slick-center .bigScale {
 
  /* transform: scale(1.01);  */
  transition: transform 0.3s ease-in-out; 
}
.project {
  opacity: 0.5;
  
 
}



.css-dqr9h-MuiRating-label {
  font-size: 37px;
}

.react-multiple-carousel__arrow {
  
  position: absolute;
  top: 140px;

}
@media (max-width: 576px) {
  .react-multiple-carousel__arrow {
    display: none;
  }
}


@media only screen and (max-width: 600px) {
  .project {
    opacity: 0;
    
   
  }
}