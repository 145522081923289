body {
  margin: 0;
  font-family: 
  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif,
   
    ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  
    monospace;
}

@font-face {
  font-family: "PoppinsExtraLight";
  src: local("Poppins"),
    url("./App/Assets/Fonts/Poppins-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsLight";
  src: local("Poppins"),
    url("./App/Assets/Fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsRegular";
  src: local("Poppins"),
    url("./App/Assets/Fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsMedium";
  src: local("Poppins"),
    url("./App/Assets/Fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsSemiBold";
  src: local("Poppins"),
    url("./App/Assets/Fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsBlack";
  src: local("Poppins"),
    url("./App/Assets/Fonts/Poppins-Black.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsBold";
  src: local("Poppins"),
    url("./App/Assets/Fonts/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsExtraBold";
  src: local("Poppins"),
    url("./App/Assets/Fonts/Poppins-ExtraBold.ttf") format("truetype");
}
