/* Container Styling */
.container {
  height: 100%;
}

.card-carousel {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 250px;
  margin: 50px 180px;
  align-items: center;
  position: relative;
  margin-bottom: 300px;

}

/* Text Styling */
.text-align {
  text-align: center;
  font-family: Arial, sans-serif;
  /* Corrected 'font-style' to 'font-family' */
  font-size: xx-large;
}

/* Testimonial Image */
.testimonial-image {
  width: 100px;
  height: 100px;
  border-radius: 20%;
  object-fit: cover;
  border: 2px solid #ddd;
  margin-left: 30px;
}

/* Card Styling */
.card {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 88%;
  max-width: 900px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  transition: all 0.75s ease;
  opacity: 0;
  z-index: 1;
}

/* Rating Stars Styles */
.rating-stars {
  position: absolute;
  top: 40px;
  right: 50px;
  display: flex;
}

.filled-stars,
.empty-stars {
  font-size: 2.5rem;
  text-shadow: 2px 2px 4px rgba(201, 191, 191, 0.5);
}

.filled-stars {
  color: gold;
}

.empty-stars {
  color: lightgray;
}

/* Text Inside Card */
.card h2 {
  line-height: 1.5;
  margin: 0;
  margin-left: 140px;
  margin-top: -90px;
  font-weight: 600;
  letter-spacing: 1px;
}

.card h5 {
  margin: 0;
  margin-left: 140px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 1px;
}

.card p {
  margin-top: -36px;
  margin-left: 70px;
  font-family: 'Poppins', sans-serif;
  /* Updated 'font-style' to 'font-family' */
  font-weight: 400;
}

.testimonial-text {
  align-items: center;
}

/* Quote Image Positioning */
.quote-image-start {
  height: 50px;
  width: 50px;
  margin-left: -1%;
  margin-top: 45px;
}

.quote-image-end {
  height: 50px;
  width: 50px;
  margin-left: 96%;
  /* Adjust this percentage as needed */
  margin-top: -22px;
  transform: translateX(-50%);
  /* Center it horizontally */
}


/* Active and Next Cards Styling */
.card.active {
  opacity: 1;
  z-index: 4;
  transform: translate(-50%, -50%) scale(1) translateY(0px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.card.next {
  opacity: 0.8;
  z-index: 3;
  transform: translate(-50%, -50%) scale(0.91) translateY(180px) translateX(-40px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  width: 80%;
  margin-left: 30px;
}

.card.next p {
  display: none;
}

.card.next h2 {
  font-size: 16px;
}

.card.next h5 {
  font-size: 10px;
}

.card.prev {
  opacity: 0.7;
  z-index: 2;
  transform: translate(-50%, -50%) scale(0.83) translateY(270px) translateX(-40px);
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
  width: 75%;
  margin-left: 30px;
  overflow: hidden;
}

.card.prev p {
  display: none;
}

.card.prev h2 {
  font-size: 14px;
}

.card.prev h5 {
  font-size: 10px;
}

.card.second-next {
  opacity: 0.6;
  z-index: 1;
  transform: translate(-50%, -50%) scale(0.76) translateY(370px) translateX(-50px);
  box-shadow: 0 8px 5px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  width: 65%;
  margin-left: 30px;
}

.card.second-next p {
  display: none;
}

.card.second-next h2 {
  font-size: 12px;
}

.card.second-next h5 {
  font-size: 10px;
}

.card.second-prev {
  /* opacity: 0.4; */
  z-index: 0;
  transform: translate(-50%, -50%) scale(0.69) translateY(374px) translateX(-60px);
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.02);
  overflow: hidden;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .card-carousel {
    margin: 50px 100px;
    margin-bottom: 200px;
  }

  .testimonial-image {
    width: 80px;
    height: 80px;
  }

  .card {
    width: 90%;
  }

  /* .quote-image-end {
    margin-left: 650px;
  } */
}

@media (max-width: 768px) {
  .container {
    height: 510px;
  }

  .card-carousel {
    margin: 40px 50px;
    margin-bottom: 150px;
  }

  .testimonial-image {
    width: 70px;
    height: 70px;
  }

  .card h2 {
    margin-left: 120px;
    margin-top: -70px;
  }

  .card h5 {
    margin-left: 120px;
  }

  .card p {
    margin-left: 80px;
  }

  .quote-image-end {
    height: 28px;
    width: 28px;
  }

  .quote-image-start {
    height: 28px;
    width: 28px;
  }

  /* 
  .quote-image-end {
    margin-left: 550px;
  } */
}

@media (max-width: 768px) {
  .rating-container {
    display: none;
  }
}

@media (max-width: 480px) {

  .container {
    height: 533px;
    margin-top: 16%;
  }

  .card-carousel {
    margin: 30px 20px;
    margin-bottom: 100px;
  }

  .testimonial-image {
    width: 60px;
    height: 60px;
  }

  .rating-container {
    display: none;
  }

  .card h2 {
    margin-left: 100px;
    margin-top: -60px;
    font-size: 1.2rem;
  }

  .card h5 {
    margin-left: 100px;
    font-size: 1rem;
  }

  .card p {
    margin-left: 60px;
    font-size: 0.9rem;
  }

  .quote-image-start {
    height: 25px;
    width: 25px;
    margin-left: 20px;
  }

  .quote-image-end {
    height: 25px;
    width: 25px;
    margin-left: 280px;
    margin-top: -22px;
  }

}

@media (max-width: 320px) {
  .quote-image-end {
    margin-left: 230px;

  }
}